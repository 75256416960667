import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Headroom from "react-headroom"
import $ from 'jquery' // important: case sensitive.
import CloseIcon from '@material-ui/icons/Close';
import scrollTo from 'gatsby-plugin-smoothscroll';
import react, {Component} from 'react';
import axios from 'axios';
import '../styles/header.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default class Form extends Component{

    state={
        name:'',
        phone:'',
        email:'',
        subject:'',
        message:'',
        sent:false
    }

    handleName=(e)=>{
        this.setState({
            name:e.target.value
        })
    }
    handlePhone=(e)=>{
        this.setState({
            phone:e.target.value
        })
    }
    handleEmail=(e)=>{
        this.setState({
            email:e.target.value
        })
    }
    handleSubject=(e)=>{
        this.setState({
            subject:e.target.value
        })
    }
    handleMessage=(e)=>{
        this.setState({
            message:e.target.value
        })
    }

    submitForm = (e) => {
        let sucesso = {}
        sucesso.name = "Mensagem enviada. Obrigado"
        toast.success(`${sucesso.name}`, {
        position: toast.POSITION.TOP_RIGHT
             });
        const {name, email, message, phone, subject} = this.state;
        const key = "d4y4ehg678ve5rbve4$2edfA";

        // create a new XMLHttpRequest
        var xhr = new XMLHttpRequest();
    
        // get a callback when the server responds
        xhr.addEventListener('load', () => {
            // update the response state and the step
            
            this.setState ({
                emailStatus: xhr.responseText
            });
        });
        // open the request with the verb and the url
        xhr.open('POST', 'http://localhost:8888/api/index.php');

        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                                
        xhr.send('sendto='+email+'&name='+name+'&message='+message+'&subject='+subject+'&phone='+phone+'&k='+key);                                


        // reset the fields
        this.setState({
            name: '',
            email: '',
            message: '',
            phone: '',
            subject: ''
        });
        e.preventDefault();
    }

    //resetar
    resetForm=()=>{
        this.setState({
            name:'',
            phone:'',
            email:'',
            subject:'',
            message:''
        })
        
        setTimeout(()=>{
            this.setState({
                sent:false,
            })
        }, 3000)
    }

    render() {

        return (
            <div id="contact">
                <div className="titulo-form play-regular">
                    Podemos ajudar?
                </div>
                <div className="desc play-regular">
                    Entre em contacto connosco.
                </div>
            <form onSubmit={this.submitForm} >
            
             <fieldset>
              <input className="play-regular" placeholder="Nome" type="text" name="name" id="name" required autoFocus value={this.state.name} onChange={this.handleName} />
              </fieldset>
              <fieldset>
              <input className="play-regular" placeholder="E-mail"  type="email" name="email" id="email" value={this.state.email} onChange={this.handleEmail} />
              </fieldset>
               <fieldset>
              <input className="play-regular" placeholder="Telemóvel"  type="tel" name="phone" id="phone" value={this.state.phone} onChange={this.handlePhone}  />
              </fieldset> 
              <fieldset>
              <input className="play-regular" placeholder="Assunto"  type="text" name="subject" id="subject" value={this.state.subject} onChange={this.handleSubject} />
              </fieldset>
              <fieldset>
              <textarea className="play-regular" placeholder="Escreva aqui a sua mensagem"  name="message" id="message" rows="5" value={this.state.message} onChange={this.handleMessage} />
              </fieldset>
            <button className="submitBtn play-bold" type="submit">Enviar</button>
            <ToastContainer />
            
          </form>
          </div>
        )
    }

}

